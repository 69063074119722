<template>
  <b-form>
    <b-card title="Ekle">
      <b-form-group label="Başlık">
        <b-form-input
          v-model="award.title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group label="Metin">
        <quill-editor
          v-model="award.content"
        />
      </b-form-group>
    </b-card>
    <b-card title="Görsel">
      <b-form-group label="Görsel Yükle">
        <b-form-file
          v-model="award.upload_image"
          placeholder="Bir görsel seçin veya buraya bırakın..."
          drop-placeholder="Görseli buraya bırakın..."
          browse-text="Görsel Seçin"
        />
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormInput,
  BFormFile,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
// eslint-disable-next-line
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'Add',
  components: {
    BForm,
    BCard,
    BFormInput,
    BFormFile,
    BFormGroup,
    BButton,
    quillEditor,
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        title: null,
      },
    }
  },
  computed: {
    award() {
      return this.$store.getters['awards/getAward']
    },
    saveStatus() {
      return this.$store.getters['awards/getAwardSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          title: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.$store.dispatch('awards/awardReset')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.award.submitStatus = true
      this.$store.dispatch('awards/awardSave', this.award)
    },
  },
}
</script>
